/*!
 *
 * Angle - Bootstrap Admin Template
 *
 * Version: 4.8.1
 * Author: @themicon_co
 * Website: http://themicon.co
 * License: https://wrapbootstrap.com/help/licenses
 *
 */

import $ from 'jquery';

import initScreenFull from './modules/common/fullscreen';
import initLoadCSS from './modules/common/load-css';
// import initTranslation from './modules/common/localize';
import initNavbarSearch from './modules/common/navbar-search';
import initSidebar from './modules/common/sidebar';
import initTableCheckAll from './modules/common/table-checkall';
import initTriggerResize from './modules/common/trigger-resize';
import { StateToggler, initToggleState } from './modules/common/toggle-state';
import { initCardDismiss, initCardCollapse, initCardRefresh } from './modules/common/card-tools';
import initBootstrap from './modules/common/bootstrap-start';

import initColorPicker from './modules/forms/color-picker';
import initFormsDemo from './modules/forms/forms';
import initImageCropper from './modules/forms/imagecrop';
import initSelect2 from './modules/forms/select2';
import initDropzone from './modules/forms/upload';
import initWizard from './modules/forms/wizard';
import initXEditable from './modules/forms/xeditable';
import './modules/forms/validation';
import '../rentals_app_init';
import '../dwolla';


export default function initApp() {
    initColorPicker();
    initFormsDemo();
    initImageCropper();
    initSelect2();
    initDropzone();
    initWizard();
    initXEditable();
    // Init modules
    initBootstrap();
    initScreenFull();
    initLoadCSS();
    // initTranslation();
    initNavbarSearch();
    initSidebar();
    initTableCheckAll();
    initTriggerResize();
    initToggleState();
    // card tools
    initCardDismiss();
    initCardCollapse();
    initCardRefresh();

    // Restore body classes
    // -----------------------------------
    var $body = $('body');
    new StateToggler().restoreState($body);

    // enable settings toggle after restore
    $('#chk-collapsed').prop('checked', $body.hasClass('aside-collapsed'));
    $('#chk-collapsed-text').prop('checked', $body.hasClass('aside-collapsed-text'));
    $('#chk-boxed').prop('checked', $body.hasClass('layout-boxed'));
    // set saved theme as checked
    $('#' + ($('#user_theme').val() || 'theme-a')).find('input[type=radio]').prop('checked', true)

    // When ready display the offsidebar
    $('.offsidebar.d-none').removeClass('d-none');
}
