$("#js-source-select").on("change", function() {
    if ($("#js-source-select option:selected")[0].value == 'add_account') {
        rentalId = document.getElementById("forms_ach_rental_id").value
        document.location.href = '/ach/add_account?rental_id=' + rentalId
    }
})
$("#js-dwolla-form").on("submit", function () {
    dwolla.configure("sandbox");
    var token = document.getElementById("token").value;
    var bankInfo = {
        routingNumber: document.getElementById("routingNumber").value,
        accountNumber: document.getElementById("accountNumber").value,
        type: document.getElementById("type").value,
        name: document.getElementById("name").value,
    };
    dwolla.fundingSources.create(token, bankInfo, callback);
    return false;
});

function callback(err, res) {
    // console.log(err)
    // console.log(res)
    $('.flash.slide').fadeOut(500, function() {
        if (res) {
            fundingSource = res['_links']['funding-source']['href']
            rentalId = document.getElementById("rental_id").value
            document.location.href = '/ach/microdeposits?rental_id=' + rentalId + '&selected_name=' + document.getElementById("name").value
        } else if (err) {
            try {
                flash = $('#js-flash')
                // flash.css('display', 'inherit')
                message = ''
                if (err['code'] == "InvalidAccessToken") {
                    message = 'Please refresh the page and try again.'
                } else if (err.hasOwnProperty('_embedded')) {
                    message = err['_embedded']['errors'][0]['message']
                } else if (err.hasOwnProperty('message')) {
                    message = err['message']
                    if (message.indexOf(":") >= 0) {
                        message = message.substring(0, message.indexOf(":"))
                    }
                }
                flash.find('.flash-message').text(message)
                flash.appendTo($('.flash-container')[0])
                $('#js-flash.slide').fadeIn(1000);
            } catch (e) {
                console.log(e)
            }
        }
    });
}
