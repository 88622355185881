// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("modernizr/modernizr.custom.js");
require("@rails/ujs").start()
//= require jquery
//= require jquery_ujs // Necessary for confirmation dialog popups
// //require("turbolinks").start()
// require("@rails/activestorage").start()
// require("channels")
require("select2");

//--- Bootstrap
import 'bootstrap';
import "sweetalert";

import appInit from './angle/app.init.js';
document.addEventListener('DOMContentLoaded', appInit);



$('form').on('click', '.remove_fields', function(event) {       
    $(this).prev('input[type=hidden]').val('1')
    $(this).closest('.subform-group').hide()
    event.preventDefault()
})

$('form').on('click', '.add_fields', function(event) {
    var time = new Date().getTime()
    var regexp = new RegExp($(this).data('id'), 'g')
    $(this).before($(this).data('fields').replace(regexp, time))
    event.preventDefault()
})

$('.js-sidebar-boxed').on('click', function() {
    setDisplay('layout-boxed');
    // setTimeout(checkToggles, 100);
})
$('.js-sidebar-collapsed').on('click', function() {
    setDisplay('aside-collapsed');
    setTimeout(checkToggles, 100);
})
$('.js-sidebar-collapsed-text').on('click', function() {
    setDisplay('aside-collapsed-text');
    // setTimeout(checkToggles, 100);
})
function setDisplay(value) {
    $.ajax({
        url: '/users/set_display',
        method: 'POST',
        data: {
            display: value
        },
        success: function (data) {
            // console.log(data)
        }
    });
}

$('.js-theme').on('click', function() {
    setTheme(this.id);
})
$('.js-clear-theme').on('click', function() {
    setTheme('');
})
function setTheme(value) {
    $.ajax({
        url: '/users/set_theme',
        method: 'POST',
        data: {
            theme: value
        },
        success: function (data) {
            console.log(data)
        }
    });
}

function checkToggles() {
    var $body = $('body');

    // enable settings toggle after restore
    $('#chk-boxed').prop('checked', $body.hasClass('layout-boxed'));
    $('#chk-collapsed').prop('checked', $body.hasClass('aside-collapsed'));
    $('#chk-collapsed-text').prop('checked', $body.hasClass('aside-collapsed-text'));
}

$('.search-bar').click(function(e) {
    e.preventDefault();
    e.stopPropagation();
    return false;
})
$('.search-bar').keypress(function (e) {
    if (e.keyCode === 10 || e.keyCode === 13) {
        console.log(e.keycode)
        e.preventDefault();
        e.stopPropagation();
        $('.filter-form').submit()
        return false;
    }
});
$('.filter-clear').click(function(e) {
    $('.filter input[type="text"]').val('');
    $('.filter select').val('').trigger('change');
    return false;
});
$('#collapseFilter').on('show.bs.collapse', function () {
    $('#collapsed').val('false')
})
$('#collapseFilter').on('hide.bs.collapse', function () {
    $('#collapsed').val('true')
})

// For buttons inside of accordion
$('.js-accordion-int-btn').on('mouseenter', function() {
    $('.accordion-button').attr('data-bs-toggle', '');
});
  
$('.js-accordion-int-btn').on('mouseleave', function() {
    $('.accordion-button').attr('data-bs-toggle', 'collapse');
});
  
// yourButton.addEventListener('click', (e) => {
//     // do whatever you want...
// });

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
