import swal from "sweetalert2";
import Rails from "@rails/ujs";

Rails.confirm = function (message, element) {
  const swalWithBootstrap = swal.mixin({
    buttonsStyling: true,
  });

  swalWithBootstrap
    .fire({
      html: message,
      title: "Confirmation",
      confirmButtonText: "Confirm",
      confirmButtonColor: "#f47f7f",
      showCancelButton: true,
      cancelButtonText: "Cancel",
    })
    .then((result) => {
      if (result.value) {
        element.removeAttribute("data-confirm");
        element.click();
      }
    });
};

$('#lease-options-js').on('click', function() {
  const swalWithBootstrap = swal.mixin({
    buttonsStyling: true,
  });

  swalWithBootstrap
    .fire({
      html: 'Download the lease or have it emailed to you?',
      title: "Lease Options",
      confirmButtonText: "Download",
      confirmButtonColor: "#37bc9b",
      showDenyButton: true,
      denyButtonText: 'Email',
      denyButtonColor: '#5d9cec',
      showCancelButton: true,
      cancelButtonText: "Cancel",
    })
    .then((result) => {
      if (result.isConfirmed) {
        window.open(window.location.href + '/lease.pdf', '_blank')
      } else if (result.isDenied) {
        $('#email-lease-js').trigger('click');
        window.location.replace(window.location.href + '/email_lease')
      } else if (result.isDismissed) {
        return false;
      }
    });
});

// $('.disable-button-js').on('click', function() {
//   const swalWithBootstrap = swal.mixin({
//     buttonsStyling: true,
//   });

//   swalWithBootstrap
//     .fire({
//       html: 'Are you sure you want to disable?',
//       title: "Confirmation",
//       confirmButtonText: "Disable",
//       confirmButtonColor: "#f47f7f",
//       showCancelButton: true,
//       cancelButtonText: "Cancel",
//     })
//     .then((result) => {
//       if (result.isConfirmed) {
//         window.location.replace(window.location.href + '/email_lease')
//       } else if (result.isDismissed) {
//         return false;
//       }
//     });
// });
