import $ from 'jquery';

$('#rental_start').on("change", updateRent);
$('#weeks').on("change", updateRent);
$('#cost').on("keyup", updateSummary);
$('#insurance_cost').on("keyup", updateSummary);
$('#processing_cost').on("keyup", updateSummary);
$('#security_deposit').on("keyup", updateSummary);
$('#pet_fee').on("keyup", updateSummary);
setFieldsAndRent();
updateSummary();

function setFieldsAndRent() {
    var rent = 0;
    if ($('#rate_price').val()) {
        var base_price = parseFloat($('#rate_price').val());
        var rates = JSON.parse($('#rate_amounts').val());
        var date_ele = $('#rental_start option:selected');
        var week = parseInt(date_ele.attr('data-week'));
        var weeks = $('#weeks').val();
        var rent_hash = {'weeks': [], 'rates': []}

        if (week && weeks) {
            for (let i = 0; i < weeks; i++) {
                var week_rate = parseFloat(rates[week]) || base_price;
                rent += week_rate;
                week += 1;
                rent_hash['weeks'].push(week)
                rent_hash['rates'].push(week_rate)
            }
            $('#rate_weekly').val(JSON.stringify(rent_hash));
            $('#custom_start').val('').change();
            $('#custom_end').val('').change();
        }
    }
    return rent;
}

function updateRent() {
    var rent = setFieldsAndRent();

    if (rent > 0) {
        updateEle($('.booking_cost'), rent)
        $('#cost').val(rent.toFixed(2));
        updateSummary();
    }
}

function updateSummary() {
    updateEle($('.booking_cost'), $('#cost').val())
    updateEle($('.booking_vrdp'), $('#insurance_cost').val())
    updateEle($('.booking_proc'), $('#processing_cost').val())
    updateEle($('.booking_dep'), $('#security_deposit').val())
    updateEle($('.booking_pet'), $('#pet_fee').val())
    updateTotal();
}

function updateTotal() {
    var rent = parseFloat($('#cost').val()) || 0;
    var vrdp = parseFloat($('#insurance_cost').val()) || 0;
    var proc_fee = parseFloat($('#processing_cost').val()) || 0;
    var sec_dep = parseFloat($('#security_deposit').val()) || 0;
    var pet_fee = parseFloat($('#pet_fee').val()) || 0;
    var total = parseFloat(rent + vrdp + proc_fee + sec_dep + pet_fee)
    updateEle($('.booking_total'), total);
}

function updateEle(ele, val) {
    if (val >= 0) {
        ele.text(formatMoney(parseFloat(val || 0)));
    }
}

function formatMoney(number) {
    return number.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
}
